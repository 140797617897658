import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import {useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";

function AutocompleteInput({
  // Props
  type = "multiple",
  name,
  control,
  defaultValue,
  "data-cy": dataCy,
  options,
  getOptionLabel = (option) => option?.name || "",
  label,
  required = true,
  disabled = false,
  rules,
  variant = "standard",
  width,
}) {
  const context = useFormContext();
  const theme = useTheme();

  if (type === "single") {
    return (
      <Controller
        name={name}
        control={context?.control || control}
        defaultValue={defaultValue !== undefined ? defaultValue : context?.defaultValues[name]}
        shouldUnregister
        render={({field: {onChange, value}, fieldState: {error}}) => (
          <Autocomplete
            data-cy={dataCy}
            options={options}
            disabled={disabled}
            size="small"
            sx={{width}}
            getOptionLabel={getOptionLabel}
            slotProps={{
              paper: {
                sx: [
                  theme.palette.mode === "dark" && {
                    background: theme.palette.background.grey,
                  },
                ],
              },
            }}
            renderOption={(props, option) => (
              <li {...props} data-cy={`${dataCy}-${option.id}`}>
                {getOptionLabel(option)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={variant}
                label={label}
                margin="normal"
                error={!!error}
                helperText={error ? error.message : null}
                sx={{mt: 0}}
                required={required}
              />
            )}
            value={value}
            onChange={(e, data) => onChange(data)}
            isOptionEqualToValue={(option, val) =>
              val === undefined || val === "" || (option.id || option) === (val.id || val)
            }
          />
        )}
        rules={rules}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={context?.control || control}
      defaultValue={defaultValue !== undefined ? defaultValue : context?.defaultValues[name]}
      shouldUnregister
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Autocomplete
          data-cy={dataCy}
          options={options}
          disabled={disabled}
          multiple
          size="small"
          disableCloseOnSelect
          sx={{width}}
          getOptionLabel={getOptionLabel}
          renderOption={(props, option, {selected}) => (
            <li {...props} data-cy={`${dataCy}-${option.id}`} value={getOptionLabel(option)}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                sx={{my: 0, mr: 2, ml: 0, p: 0}}
                checked={selected}
              />
              {getOptionLabel(option)}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant}
              label={label}
              margin="normal"
              error={!!error}
              helperText={error ? error.message : null}
              sx={{mt: 0}}
              required={required}
            />
          )}
          value={value}
          onChange={(e, data) => onChange(data)}
          isOptionEqualToValue={(option, val) => {
            const optionId = typeof option === "string" ? option : option.id;
            const valueId = typeof val === "string" ? val : val.id;

            return val === undefined || val === "" || optionId === valueId;
          }}
        />
      )}
      rules={rules}
    />
  );
}

AutocompleteInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  "data-cy": PropTypes.string,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  rules: PropTypes.object,
  variant: PropTypes.string,
  width: PropTypes.number,
};

export default AutocompleteInput;
