/* eslint-env browser */
import React from "react";
import {useIdleTimer} from "react-idle-timer";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../axiosClient.js";
import useJwt from "./contexts/useJwt.jsx";
import useLogout from "./hooks/useLogout.jsx";

//---------------------------------------------------------------------------
// Uses the current time and the JWT expiration to calculate when we need
// to clean up and log out
//---------------------------------------------------------------------------
const calculateRemainingMs = (expiration) => {
  if (!expiration) {
    return 0;
  }
  const currentTime = Date.now();
  // Make sure we have 5 seconds left when we auto-logout so that the JWT is still valid
  // for any cleanup API calls we need to make.
  const LOGOUT_TIME_BUFFER_MS = 5000;
  return expiration * 1000 - currentTime - LOGOUT_TIME_BUFFER_MS;
};

function AutoLogout({
  // Props
  throttle,
  warningSeconds,
}) {
  //---------------------------------------------------------------------------
  // Global variables & state
  //---------------------------------------------------------------------------
  const [idle, setIdle] = React.useState(false);
  const {username, jwt, setJwt, expiration, unauthorized} = useJwt();

  //---------------------------------------------------------------------------
  // Idle state - this goes true to trigger the logout warning
  //---------------------------------------------------------------------------
  const handleOnIdle = React.useCallback(() => {
    setIdle(true);
  }, []);

  //---------------------------------------------------------------------------
  // This callback is used to go to the login page. Until that page is
  // converted to React, we'll use query params to pass the redirect and message.
  //---------------------------------------------------------------------------
  const logout = useLogout();
  const goToLoginPage = React.useCallback(
    async (message = "You were automatically logged out.") => {
      logout(message);
    },
    [logout]
  );

  //---------------------------------------------------------------------------
  // As long as the user is active, refresh the JWT every <throttling> seconds
  //---------------------------------------------------------------------------
  const refreshJwtOnAction = React.useCallback(async () => {
    if (!expiration || !username) {
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: "/refreshJwt",
      });
      const newJwt = response.headers["access-token"];
      if (typeof newJwt === "string" && newJwt.length >= 16) {
        if (newJwt !== jwt) {
          setJwt(newJwt);
        }
      } else {
        // This generally means that the user has been disabled
        goToLoginPage();
      }
    } catch (err) {
      // A 401 usually means that the JWT was manually deleted from local storage
      if (err.response?.status === 401) {
        goToLoginPage();
      } else {
        // Do nothing
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  }, [expiration, username, jwt, setJwt, goToLoginPage]);

  //---------------------------------------------------------------------------
  // This sets up the actual idle detection system
  //---------------------------------------------------------------------------
  const timeoutMs = React.useMemo(() => {
    const remainingMs = calculateRemainingMs(expiration);
    const warningMs = warningSeconds * 1000;
    return remainingMs > warningMs ? remainingMs - warningMs : 1000;
  }, [expiration, warningSeconds]);
  const {start, reset, pause, setOnAction, setOnIdle} = useIdleTimer({
    timeout: timeoutMs,
    throttle,
    stopOnIdle: true,
    crossTab: true,
    startManually: true,
  });

  //---------------------------------------------------------------------------
  // Manage the auto-logout timer
  //---------------------------------------------------------------------------
  React.useEffect(() => {
    const remainingMs = calculateRemainingMs(expiration);

    if (remainingMs <= 0 || unauthorized) {
      goToLoginPage(unauthorized || "You were logged out from another tab.");
      return () => {};
    }
    const timer = setTimeout(goToLoginPage, remainingMs);

    if (remainingMs <= warningSeconds * 1000) {
      // Display the warning snackbar
      setIdle(true);
      pause();
    } else {
      // Start the idle timer
      setIdle(false);
      reset();
      start();
    }

    // The idle timer doesn't clean itself up nicely, so we need to set and remove our
    // callback functions manually
    setOnIdle(handleOnIdle);
    setOnAction(refreshJwtOnAction);
    return () => {
      setOnIdle(() => {});
      setOnAction(() => {});
      clearTimeout(timer);
      pause();
      reset();
    };
  }, [
    expiration,
    goToLoginPage,
    handleOnIdle,
    pause,
    refreshJwtOnAction,
    reset,
    setOnAction,
    setOnIdle,
    start,
    unauthorized,
    warningSeconds,
  ]);

  //---------------------------------------------------------------------------
  // Used by the snackbar to refresh the JWT and restart the idle timer
  //---------------------------------------------------------------------------
  const handleClick = React.useCallback(() => {
    setIdle(false);
    refreshJwtOnAction();
    start();
  }, [refreshJwtOnAction, start]);

  if (!expiration) {
    return null;
  }

  //---------------------------------------------------------------------------
  // Render the snackbar if needed
  //---------------------------------------------------------------------------
  return (
    <Snackbar
      data-cy="auto-logout-notifier"
      open={idle}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      sx={{mt: 6.5}}
    >
      <SnackbarContent
        message="You will be logged out soon."
        action={
          <Button
            aria-label="close"
            data-cy="auto-logout-refresh-button"
            color="inherit"
            size="small"
            onClick={handleClick}
          >
            Stay logged in
          </Button>
        }
      />
    </Snackbar>
  );
}

AutoLogout.propTypes = {
  throttle: PropTypes.number.isRequired,
  warningSeconds: PropTypes.number.isRequired,
};

// Export a memo so the component is only re-rendered if the props change, not if the parent re-renders.
const memoAutoLogout = React.memo(AutoLogout);
export default memoAutoLogout;
