/* eslint-disable complexity */
/* eslint react/no-unstable-nested-components: ["error", {"allowAsProps": true}] */
/* eslint-env browser */
import React from "react";
import {Helmet} from "react-helmet-async";
import {useForm, useFormState} from "react-hook-form";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import uniq from "lodash/uniq";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import AddToHomeScreen from "@mui/icons-material/AddToHomeScreen";
import DeveloperMode from "@mui/icons-material/DeveloperMode";
import PhonelinkLock from "@mui/icons-material/PhonelinkLock";
import PhonelinkRing from "@mui/icons-material/PhonelinkRing";
import PhonelinkSetup from "@mui/icons-material/PhonelinkSetup";
import TapAndPlay from "@mui/icons-material/TapAndPlay";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {
  getFirmwareReleasesByDeviceType,
  parseVersionFromFileName,
} from "../../components/common/FirmwareParsing.js";
import useJwt from "../../components/contexts/useJwt.jsx";
import AutocompleteInput from "../../components/form-inputs/AutocompleteInput.jsx";
import FormStringInput from "../../components/form-inputs/FormStringInput.jsx";
import Alert from "../../components/primitives/Alert.jsx";
import CancelButton from "../../components/primitives/CancelButton.jsx";
import {
  deviceForwardingModes,
  getDeviceForwardingDescription,
} from "../../components/primitives/ForwardingModes.jsx";
import TableLoading from "../../components/primitives/TableLoading.jsx";
import Page404 from "../Page404.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const DeviceGroup = React.lazy(() => import(/* webpackPrefetch: true */ "./DeviceGroup.jsx"));
const DeviceGroupHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./DeviceGroupHeader.jsx"));
const SubmitDialog = React.lazy(() => import(/* webpackPrefetch: true */ "./SubmitDialog.jsx"));

//--------------------------------------------------------------------------
export function allowBulkDeviceActions(isInAnyRole, facilityDisabled) {
  if (arguments.length !== 2) {
    throw new Error("Wrong number of arguments");
  }
  if (facilityDisabled) {
    return false;
  }
  return isInAnyRole(["tzAdmin", "warehouse", "facilityAdmin"]);
}

function BulkDeviceActions() {
  //---------------------------------------------------------------------------
  // Form and button loading state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true); // when data is being loaded from API (i.e. page load or clicking process)
  const [processing, setProcessing] = React.useState(false); // time after devices are entered but before the entire form is submitted

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole, facilityDisabled, userFacilityId} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [facilities, setFacilities] = React.useState({});
  const [forwardingFacility, setForwardingFacility] = React.useState(null);
  const [deviceVariants, setDeviceVariants] = React.useState({});
  const [deviceTypes, setDeviceTypes] = React.useState({});
  const [firmwareVersions, setFirmwareVersions] = React.useState([]);
  const [validDevices, setValidDevices] = React.useState({});
  const [invalidDevices, setInvalidDevices] = React.useState({});

  const getData = React.useCallback(async () => {
    const urlQuery = {
      order: [["name", "ASC"]],
      id: {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]},
    };
    if (isInAnyRole(["tzAdmin", "warehouse"])) {
      delete urlQuery.id;
    }

    try {
      const [
        {data: facilitiesResponse},
        {data: deviceVariantsResponse},
        {data: deviceTypesResponse},
        {data: firmwarePathsResponse},
      ] = await Promise.all([
        axios({
          method: "get",
          url: "/facilities",
          params: urlQuery,
        }),
        axios({
          method: "get",
          url: "/deviceVariants",
        }),
        axios({
          method: "get",
          url: "/deviceTypes",
        }),
        axios({
          method: "get",
          url: "/firmware/storage/filePaths",
        }),
      ]);

      // Format the arrays into objects for O(1) access
      const formattedFacilities = keyBy(facilitiesResponse, "id");
      Object.values(formattedFacilities).forEach((facility) => {
        // Attach the parent name to child facilities
        if (facility.parentFacilityId && formattedFacilities[facility.parentFacilityId]?.name) {
          // eslint-disable-next-line no-param-reassign
          facility.name += ` (${formattedFacilities[facility.parentFacilityId].name})`;
        }
      });
      const formattedDeviceVariants = keyBy(deviceVariantsResponse, "gtin");
      const formattedDeviceTypes = keyBy(deviceTypesResponse, "id");

      // Format firmware versions by device types
      const formattedFirmwareReleases = firmwarePathsResponse.map((firmwarePath) => ({
        id: firmwarePath,
        version: parseVersionFromFileName(firmwarePath),
      }));
      const formattedFirmwareVersions = getFirmwareReleasesByDeviceType(
        formattedFirmwareReleases,
        deviceTypesResponse,
        isInAnyRole(["tzAdmin", "warehouse"])
      );

      setFacilities(formattedFacilities);
      setDeviceVariants(formattedDeviceVariants);
      setDeviceTypes(formattedDeviceTypes);
      setFirmwareVersions(formattedFirmwareVersions);
    } catch (err) {
      setError(err.message);
    }
  }, [isInAnyRole, userFacilityId]);
  React.useEffect(() => {
    if (loading && !processing) {
      getData().then(() => setLoading(false));
    }
  }, [loading, processing, getData]);

  //---------------------------------------------------------------------------
  // Form submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control, watch, reset, setValue, trigger} = useForm();
  const {isDirty} = useFormState({control});
  const watchAction = watch("action");
  const watchAllFields = watch();

  const handleDiscard = React.useCallback(() => {
    setValidDevices({});
    setInvalidDevices({});
    reset();
    setProcessing(false);
  }, [reset]);
  const handleCancel = React.useCallback(() => {
    setValidDevices({});
    setInvalidDevices({});
    setProcessing(false);
  }, []);

  const onProcess = React.useCallback(
    async (data) => {
      const devicesWithValidBarcodes = [];
      const sortedValidDevices = []; // Array of valid devices
      const sortedInvalidDevices = {}; // Mapping of error -> array of devices that threw that error

      // Manually trigger validation
      const valid = await trigger(["action", "devices"]);
      if (!valid) {
        setLoading(false);
        setProcessing(false);
        return;
      }

      setLoading(true);
      setProcessing(true);

      //---------------------------------------------------------------------------
      // Split and filter barcodes from the textarea input
      //---------------------------------------------------------------------------
      const barcodes = uniq(
        data.devices
          .replace(/(?:\r?\n|\s)+|,/g, "\n")
          .split("\n")
          .map((barcode) => barcode.trim())
          .filter((barcode) => barcode !== "")
      );

      barcodes.forEach((barcode) => {
        //---------------------------------------------------------------------------
        // Separate the GTIN and tzSerial from the barcode
        //---------------------------------------------------------------------------
        const separatedBarcode = barcode.match(/(?:01)?(?<gtin>[\d]{14})?(?:21)?(?<tzSerial>[\w]{7,15})/);
        const device = {barcode, ...separatedBarcode?.groups};

        try {
          // If the barcode cannot be parsed, throw invalid barcode error
          if (!device.tzSerial) {
            throw new Error("Invalid barcode");
          }

          devicesWithValidBarcodes.push(device);
        } catch (err) {
          if (sortedInvalidDevices[err.message]) {
            sortedInvalidDevices[err.message].push(device);
          } else {
            sortedInvalidDevices[err.message] = [device];
          }
        }
      });

      //---------------------------------------------------------------------------
      // Get the devices with valid barcodes
      //---------------------------------------------------------------------------
      const urlQuery = {
        tzSerial: {$or: devicesWithValidBarcodes.map(({tzSerial}) => tzSerial)},
        operationalState: {$ne: "terminated"},
        facilityId: {$or: [{$like: `${userFacilityId}_%`}, {$eq: userFacilityId}]},
      };
      if (isInAnyRole(["tzAdmin", "warehouse"])) {
        delete urlQuery.facilityId;
      }
      const {data: foundDevices} = await axios({
        method: "get",
        url: "/devices",
        params: urlQuery,
      });

      devicesWithValidBarcodes.forEach((device) => {
        try {
          //---------------------------------------------------------------------------
          // Confirm that each device exists
          //---------------------------------------------------------------------------
          const foundDevice = foundDevices.find(({tzSerial}) => tzSerial === device.tzSerial);

          // If a device was not returned, throw device does not exist error
          if (!foundDevice) {
            throw new Error("Device does not exist");
          }
          Object.assign(device, foundDevice);

          //---------------------------------------------------------------------------
          // Confirm that the selected action is allowed for the device's current state
          //---------------------------------------------------------------------------
          // If performing any action besides 'updateFirmware', the device cannot be pending assignment
          if (data.action !== "updateFirmware" && device.pendingFacilityId) {
            throw new Error("Device is pending assignment");
          }
          // If performing any action besides 'updateFirmware', the device cannot be pending activation
          if (data.action !== "updateFirmware" && device.pendingState === "active") {
            throw new Error("Device is pending activation");
          }
          // If performing any action besides 'updateFirmware', the device cannot be pending suspension
          if (data.action !== "updateFirmware" && device.pendingState === "inactive") {
            throw new Error("Device is pending suspension");
          }
          // If performing an action besides 'assign', the device must be assigned (applicable only to tzAdmins/warehouse)
          if (!data.action.startsWith("assign") && !device.facilityId) {
            throw new Error("Device is not assigned");
          }
          // Holter devices are only valid for updateFirmware, updateForwarding, and assignment actions
          if (
            !["updateFirmware", "assign", "assignAndSuspend", "updateForwarding"].includes(data.action) &&
            !device.iccid
          ) {
            throw new Error("Device does not have forwarding capabilities");
          }
          // If performing an action besides 'transfer', the device cannot be transferred
          if (
            data.action !== "transfer" &&
            !(data.action.startsWith("assign") && isInAnyRole(["tzAdmin", "warehouse"])) &&
            device.operationalState === "transferred"
          ) {
            throw new Error("Device is transferred");
          }
          // Device cannot already be activated if we are activating
          if (data.action === "activate" && device.operationalState === "active") {
            throw new Error("Device is already activated");
          }
          // Device cannot already be suspended if we are suspending
          if (data.action === "suspend" && device.operationalState === "inactive") {
            throw new Error("Device is already suspended");
          }
          // Device cannot already be transferred if we are transferring
          if (
            data.action === "transfer" &&
            (device.operationalState === "transferred" || device.pendingState !== null)
          ) {
            throw new Error("Device is already transferred");
          }
          // Device cannot be transferred if assigned to a non-transfer facility
          if (data.action === "transfer" && !facilities[device.facilityId].iccidTransferAgreement) {
            throw new Error("Device is not assigned to a facility with an ICCID transfer agreement");
          }
          // There must be a matching device variant for this device
          if (!deviceVariants[device.gtin]) {
            throw new Error("Device has an invalid GTIN");
          }

          sortedValidDevices.push(device);
        } catch (err) {
          if (sortedInvalidDevices[err.message]) {
            sortedInvalidDevices[err.message].push(device);
          } else {
            sortedInvalidDevices[err.message] = [device];
          }
        }
      });

      // Group valid devices by their facility to check if multiple facilities are present
      const validDevicesGroupedByFacility = groupBy(sortedValidDevices, "facilityId");
      const multipleFacilitiesAllowedForAction =
        (data.action.startsWith("assign") || !isInAnyRole(["tzAdmin", "warehouse"])) &&
        data.action !== "updateForwarding";

      // If there are multiple facilities and this is not a tzAdmin assignment, all of the devices are invalid
      if (!multipleFacilitiesAllowedForAction && Object.keys(validDevicesGroupedByFacility).length > 1) {
        sortedInvalidDevices["Cannot perform this action for devices from multiple facilities at once"] =
          sortedValidDevices;
      } else {
        // Group the valid devices by their device type
        const validProcessedDevices = groupBy(sortedValidDevices, "deviceVariant.deviceType.id");

        if (data.action === "updateForwarding") {
          setForwardingFacility(Object.keys(validDevicesGroupedByFacility)[0]);
        }
        setValidDevices(validProcessedDevices);
      }

      setInvalidDevices(sortedInvalidDevices);
      setLoading(false);
    },
    [trigger, userFacilityId, isInAnyRole, facilities, deviceVariants]
  );
  React.useEffect(() => {
    if (loading && processing) {
      onProcess(watchAllFields);
    }
  }, [loading, processing, onProcess, watchAllFields]);

  // Force the {enter} key to submit the form
  const onKeyDown = React.useCallback(
    async (e) => {
      if (e.keyCode === 13) {
        await onProcess(watchAllFields);
      }
    },
    [onProcess, watchAllFields]
  );

  const displayWarning = React.useCallback(
    (device) => {
      const deviceForwardingToInbox = device.forwardingDestination === "BitRhythm Inbox";

      return (
        (!!device.facilityId && isInAnyRole(["tzAdmin", "warehouse"])) ||
        !device.iccid ||
        (deviceForwardingToInbox && !device.availableForStudy)
      );
    },
    [isInAnyRole]
  );

  const warningMessage = React.useCallback(
    (device) => {
      const deviceForwardingToInbox = device.forwardingDestination === "BitRhythm Inbox";

      const reassignmentWarning = "Device is already assigned";
      const studyWarning =
        "Device is currently in a study - Reassignment will execute once the study is complete";
      const holterWarning = device.facilityId
        ? "This device will be reassigned, but has no SIM card to activate or suspend"
        : "This device will be assigned, but has no SIM card to activate or suspend";

      const messages = [];
      if (device.facilityId && isInAnyRole(["tzAdmin", "warehouse"])) {
        messages.push(reassignmentWarning);
      }
      if (!device.iccid) {
        messages.push(holterWarning);
      }
      if (deviceForwardingToInbox && !device.availableForStudy) {
        messages.push(studyWarning);
      }

      return messages;
    },
    [isInAnyRole]
  );

  const assignAction = React.useMemo(
    () => ({
      displayedFields: [{id: "iccid", name: "ICCID"}],
      displayWarning,
      warningMessage,
      additionalOptions: (deviceTypeId) => (
        <Grid container size={{xs: 12, md: 3}} sx={{mt: 1}}>
          <Grid size="grow" sx={{mt: 2.5}}>
            <Card square sx={{p: 2}}>
              <AutocompleteInput
                control={control}
                options={Object.values(facilities).filter((f) => !f.disabled)}
                label="Facility"
                sx={{width: "100%"}}
                name={`facility-${deviceTypeId}`}
                data-cy={`facility-input-${deviceTypeId}`}
                rules={{
                  required: `Facility is required for ${deviceTypes[deviceTypeId].name} devices`,
                }}
                type="single"
              />
            </Card>
          </Grid>
        </Grid>
      ),
    }),
    [control, deviceTypes, facilities, warningMessage, displayWarning]
  );
  const actions = {
    updateFirmware: {
      id: "updateFirmware",
      name: (
        <Box sx={{display: "inline-flex", alignItems: "center"}}>
          <PhonelinkSetup color={processing ? "" : "secondary"} fontSize="small" />
          &nbsp;&nbsp;Update Firmware
        </Box>
      ),
      confirmationText: "update the firmware of",
      displayedFields: [{id: "firmwareVersion", name: "Current Firmware Version"}],
      displayWarning: (device) => {
        const deviceForwardingToInbox = device.forwardingDestination === "BitRhythm Inbox";

        return (
          (deviceForwardingToInbox && !device.availableForStudy) ||
          (!!device.facilityId && device.operationalState === "inactive")
        );
      },
      warningMessage: (device) => {
        const deviceForwardingToInbox = device.forwardingDestination === "BitRhythm Inbox";

        const suspendedWarning =
          "Device is currently suspended - Firmware update will be downloaded once the device is activated";
        const studyWarning =
          "Device is currently in a study - Firmware update will be downloaded once the study is complete";

        const messages = [];
        if (device.facilityId && device.operationalState === "inactive") {
          messages.push(suspendedWarning);
        }
        if (deviceForwardingToInbox && !device.availableForStudy) {
          messages.push(studyWarning);
        }

        return messages;
      },
      additionalOptions: (deviceTypeId) => (
        <Grid container size={{xs: 12, md: 3}} sx={{mt: 1}}>
          <Grid size="grow" sx={{mt: 2.5}}>
            <Card square sx={{p: 2}}>
              <Grid size="grow">
                <FormStringInput
                  control={control}
                  defaultValue=""
                  label="Firmware Version"
                  name={`firmwareVersion-${deviceTypeId}`}
                  data-cy={`firmware-version-input-${deviceTypeId}`}
                  options={firmwareVersions[deviceTypeId]}
                  rules={{
                    required: `Firmware Version is required for ${deviceTypes[deviceTypeId].name} devices`,
                  }}
                />
              </Grid>
              <Grid size="grow" sx={{pt: 2}}>
                <FormStringInput
                  control={control}
                  defaultValue=""
                  label="Comment"
                  name={`comment-${deviceTypeId}`}
                  data-cy={`comment-input-${deviceTypeId}`}
                  otherProps={{
                    multiline: true,
                    maxRows: 4,
                  }}
                  rules={{
                    required: `Comment is required for ${deviceTypes[deviceTypeId].name} devices`,
                  }}
                />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ),
    },

    updateForwarding: {
      id: "updateForwarding",
      name: (
        <Box sx={{display: "inline-flex", alignItems: "center"}}>
          <TapAndPlay color={processing ? "" : "secondary"} fontSize="small" />
          &nbsp;&nbsp;Update Forwarding
        </Box>
      ),
      confirmationText: "update the forwarding of",
      displayedFields: [
        {id: "readableForwardingMode", name: "Forwarding Mode"},
        {id: "forwardingUrl", name: "Forwarding URL"},
      ],
      displayWarning: (device) => {
        const deviceForwardingToInbox = device.forwardingDestination === "BitRhythm Inbox";

        return deviceForwardingToInbox && !device.availableForStudy;
      },
      warningMessage: () =>
        "Device is currently in a study - Forwarding update will execute once the study is complete",
      additionalOptions: (deviceTypeId) => (
        <Grid container size={{xs: 12, md: 3}} sx={{mt: 1}}>
          <Grid size="grow" sx={{mt: 2.5}}>
            <Card square sx={{p: 2}}>
              <Grid size="grow">
                <FormStringInput
                  control={control}
                  defaultValue=""
                  label="Forwarding Mode"
                  name={`forwardingMode-${deviceTypeId}`}
                  data-cy={`forwarding-mode-input-${deviceTypeId}`}
                  options={Object.values(omit(deviceForwardingModes, "compatForwarding")).filter(
                    (mode) => mode !== "inboxForwarding" || facilities[forwardingFacility]?.inboxAccess
                  )}
                  rules={{
                    required: `Forwarding Mode is required for ${deviceTypes[deviceTypeId].name} devices`,
                  }}
                />
              </Grid>
              {["nativeForwarding", "useDefaultModeOnly"].includes(
                watchAllFields[`forwardingMode-${deviceTypeId}`]
              ) && (
                <Grid size="grow" sx={{pt: 2}}>
                  <FormStringInput
                    control={control}
                    defaultValue=""
                    label="Forwarding URL"
                    name={`forwardingUrl-${deviceTypeId}`}
                    data-cy={`forwarding-url-input-${deviceTypeId}`}
                    rules={{
                      required: "Forwarding URL is required",
                    }}
                  />
                </Grid>
              )}
              {watchAllFields[`forwardingMode-${deviceTypeId}`] && (
                <Grid size="grow" sx={{pt: 2}}>
                  <Alert
                    message={getDeviceForwardingDescription(
                      watchAllFields[`forwardingMode-${deviceTypeId}`],
                      facilities[forwardingFacility].forwardingMode,
                      facilities[forwardingFacility].deviceForwardingUrl
                    )}
                    level="info"
                  />
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>
      ),
    },

    ...((isInAnyRole(["tzAdmin", "warehouse"]) || Object.keys(facilities).length > 1) && {
      assign: {
        id: "assign",
        name: (
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <AddToHomeScreen color={processing ? "" : "secondary"} fontSize="small" />
            &nbsp;&nbsp;
            {`${isInAnyRole(["tzAdmin", "warehouse"]) ? "Assign" : "Reassign"} and Activate Devices`}
          </Box>
        ),
        ...assignAction,
      },

      assignAndSuspend: {
        id: "assignAndSuspend",
        name: (
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <AddToHomeScreen color={processing ? "" : "secondary"} fontSize="small" />
            &nbsp;&nbsp;
            {`${isInAnyRole(["tzAdmin", "warehouse"]) ? "Assign" : "Reassign"} and Suspend Devices`}
          </Box>
        ),
        ...assignAction,
      },
    }),

    suspend: {
      id: "suspend",
      name: (
        <Box sx={{display: "inline-flex", alignItems: "center"}}>
          <PhonelinkLock color={processing ? "" : "secondary"} fontSize="small" />
          &nbsp;&nbsp;Suspend Devices
        </Box>
      ),
      displayedFields: [
        {id: "iccid", name: "ICCID"},
        {id: "status", name: "Current State"},
      ],
      displayWarning: (device) => {
        const deviceForwardingToInbox = device.forwardingDestination === "BitRhythm Inbox";

        return deviceForwardingToInbox && !device.availableForStudy;
      },
      warningMessage: () =>
        "Device is currently in a study - SIM will be suspended once the study is complete",
    },

    activate: {
      id: "activate",
      name: (
        <Box sx={{display: "inline-flex", alignItems: "center"}}>
          <PhonelinkRing color={processing ? "" : "secondary"} fontSize="small" />
          &nbsp;&nbsp;Activate Devices
        </Box>
      ),
      displayedFields: [
        {id: "iccid", name: "ICCID"},
        {id: "status", name: "Current State"},
      ],
    },

    ...(isInAnyRole(["tzAdmin", "warehouse"]) && {
      transfer: {
        id: "transfer",
        name: (
          <Box sx={{display: "inline-flex", alignItems: "center"}}>
            <DeveloperMode color={processing ? "" : "secondary"} fontSize="small" />
            &nbsp;&nbsp;Transfer Devices
          </Box>
        ),
        displayedFields: [
          {id: "iccid", name: "ICCID"},
          {id: "status", name: "Current State"},
        ],
      },
    }),
  };

  //--------------------------------------------------------------------------
  // Role Limiting
  //--------------------------------------------------------------------------
  if (!allowBulkDeviceActions(isInAnyRole, facilityDisabled)) {
    return <Page404 />;
  }

  return (
    <>
      <Helmet>
        <title>Bulk Device Actions - BitRhythm Admin</title>
      </Helmet>

      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {(!loading || processing) && (
        <Card square sx={{mb: 4}}>
          <CardContent>
            <Grid container columnSpacing={4} sx={{p: 1}}>
              {/* Action selection */}
              <Grid size={{xs: 12, md: 6}}>
                <FormStringInput
                  data-cy="action-input"
                  control={control}
                  defaultValue=""
                  label="Action"
                  name="action"
                  options={Object.values(actions)}
                  disabled={processing}
                  rules={{required: "Action is required"}}
                />
                {["suspend", "activate"].includes(watchAction) && (
                  <Box sx={{mt: 2}}>
                    <Alert
                      message="Devices are not exempt from billing unless they have been suspended for a full month"
                      level="info"
                    />
                  </Box>
                )}
              </Grid>

              {/* Devices textarea */}
              <Grid size={{xs: 12, md: 6}}>
                <FormStringInput
                  data-cy="devices-input"
                  control={control}
                  defaultValue=""
                  label="Devices"
                  name="devices"
                  otherProps={{
                    multiline: true,
                    maxRows: 10,
                    placeholder: "Scan or paste device barcodes or serials",
                    variant: "outlined",
                    onKeyDown,
                  }}
                  rules={{required: "Scan or paste device barcodes or serials"}}
                />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions sx={{justifyContent: "end"}}>
            <Box sx={{m: 2}}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleDiscard}>
                Discard
              </CancelButton>
            </Box>
            <Box sx={{m: 2}}>
              <LoadingButton
                data-cy="process-bulk-actions-button"
                variant="contained"
                color="secondary"
                loading={loading && processing}
                onClick={() => onProcess(watchAllFields)}
                type="submit"
              >
                Process
              </LoadingButton>
            </Box>
          </CardActions>
        </Card>
      )}

      {!loading && processing && (
        <>
          {/* --------- INVALID DEVICES --------- */}
          {Object.keys(invalidDevices).length > 0 && (
            <Grid container sx={{mt: 4}}>
              <DeviceGroupHeader fields={actions[watchAction].displayedFields} />
              <Grid size={12} sx={{mt: 1}}>
                {Object.entries(invalidDevices).map(([errorMessage, devices]) => (
                  <DeviceGroup
                    key={errorMessage}
                    message={errorMessage || "Unknown Error"}
                    devices={devices}
                    facilities={facilities}
                    barcodes={watchAllFields.devices}
                    fields={actions[watchAction].displayedFields || []}
                    variant="invalid"
                    setLoading={setLoading}
                    setProcessing={setProcessing}
                    setValue={setValue}
                  />
                ))}
              </Grid>
            </Grid>
          )}

          {/* --------- VALID DEVICES --------- */}
          {Object.keys(validDevices).length > 0 && (
            <Grid container>
              {Object.entries(validDevices).map(([deviceTypeId, devices]) => (
                <Grid
                  container
                  size={12}
                  key={deviceTypeId}
                  columnSpacing={2}
                  sx={{alignItems: "start", mt: 4}}
                >
                  {/* Header and Device Rows */}
                  <Grid container size="grow">
                    <DeviceGroupHeader fields={actions[watchAction].displayedFields} />
                    <Grid size={12} sx={{mt: 1}}>
                      <DeviceGroup
                        message={`Valid devices - ${
                          deviceTypes[deviceTypeId]?.name || "Unknown device type"
                        }`}
                        devices={devices}
                        facilities={facilities}
                        barcodes={watchAllFields.devices}
                        fields={actions[watchAction].displayedFields || []}
                        variant="valid"
                        displayWarning={actions[watchAction]?.displayWarning}
                        warningMessage={actions[watchAction]?.warningMessage}
                        setLoading={setLoading}
                        setProcessing={setProcessing}
                        setValue={setValue}
                      />
                    </Grid>
                  </Grid>

                  {/* Form Inputs (Optional) */}
                  {!!actions[watchAction]?.additionalOptions &&
                    actions[watchAction]?.additionalOptions(deviceTypeId)}
                </Grid>
              ))}
            </Grid>
          )}

          <AppBar position="fixed" sx={{backgroundColor: "background.paper", top: "auto", bottom: 0}}>
            <Grid container sx={{justifyContent: "end"}}>
              <Box sx={{m: 2}}>
                <CancelButton
                  color="secondary"
                  isDirty={isDirty}
                  onClick={handleCancel}
                  message="All processed devices will be discarded."
                >
                  Cancel
                </CancelButton>
              </Box>
              <Box sx={{m: 2}}>
                <Tooltip
                  title={Object.keys(invalidDevices).length > 0 ? "Invalid devices must be removed" : ""}
                >
                  <span>
                    <SubmitDialog
                      formData={watchAllFields}
                      devices={validDevices}
                      handleSubmit={handleSubmit}
                      facilities={facilities}
                      handleDiscard={handleDiscard}
                      disabled={
                        Object.keys(validDevices).length < 1 || Object.keys(invalidDevices).length > 0
                      }
                    />
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          </AppBar>
          <Toolbar sx={{mt: 4}} />
        </>
      )}
      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        loading && <TableLoading />
      }
    </>
  );
}

export default BulkDeviceActions;
